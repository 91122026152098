<template>
  <div class="responsive-video">
    <div class="video-container-desktop">
      <video
        muted
        loop
        autoplay
        ref="videoPlayer"
        :src="videoSrc"
        type="video/mp4"
        @loadedmetadata="calculateAspectRatio"
        playsinline
      ></video>
    </div>
    <div class="video-container-mobile">
      <video
        muted
        loop
        autoplay
        ref="videoPlayerMb"
        :src="videoSrc_M"
        type="video/mp4"
        @loadedmetadata="calculateAspectRatio"
        playsinline
      ></video>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoSrc: "/videos/map7.mp4",
      videoSrc_M: "/videos/map7_square.mp4",
      aspectRatio: 16 / 9, // Change this to match your video's aspect ratio
    };
  },
  mounted() {
    window.addEventListener("resize", this.calculateAspectRatio);
    this.calculateAspectRatio();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateAspectRatio);
  },
  methods: {
    calculateAspectRatio() {
      const container = this.$el.querySelector(".video-container-desktop");
      const containerMb = this.$el.querySelector(".video-container-mobile");
      const video = this.$refs.videoPlayer;
      const videoMb = this.$refs.videoPlayerMb;

      const containerWidth = container.offsetWidth;
      const containerWidthMb = containerMb.offsetWidth;
      const newHeight = containerWidth / this.aspectRatio;
      const newHeightMb = containerWidthMb / this.aspectRatio;

      video.style.width = "100%";
      video.style.height = `${newHeight}px`;
      // videoMb.style.width = "100%";
      // videoMb.style.height = `${newHeight}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.responsive-video {
  width: 100%;
  max-width: 100vw;
}

.video-container-desktop {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio - adjust this value to match your video's aspect ratio */
  overflow: hidden;
}

.video-container-desktop video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-container-mobile {
  position: relative;
  margin-bottom: -6px;
  // overflow: hidden;
}

.video-container-mobile video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-container-mobile {
  display: none;
  @media screen and (max-width: 500px) {
    display: block;
  }
}
.video-container-desktop {
  display: block;
  @media screen and (max-width: 500px) {
    display: none;
  }
}
</style>

